import { Banner } from "@shopify/polaris";
import { setDateTimeToLastSecond } from "@smartrr/shared/utils/dateUtils";
import moment from "moment";
import React, { useMemo } from "react";

import { DatePickerModal } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import { updateShowSetUnpauseDate } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface Props {
  title?: string;
  bodyElement?: JSX.Element;
  bannerText?: string;
  initialUnpauseDate?: Date;

  onConfirm(date: Date): void;
}

const PAUSE_DATE_MAX_MONTHS_RANGE = 6;

export function SetUnpauseDateModal({ title, onConfirm, bodyElement, bannerText, initialUnpauseDate }: Props) {
  const dispatch = useSmartrrVendorDispatch();
  const closeModal = () => dispatch(updateShowSetUnpauseDate(false));
  const isOpen = useSmartrrVendorSelector(state => state.subscriptionDetails.showSetUnpauseDate);

  const initialDate = useMemo(() => {
    return new Date();
  }, []);

  const disabledDatesBefore = useMemo(() => setDateTimeToLastSecond(new Date()), []);

  const disabledDatesAfter = useMemo(
    () => moment(initialDate).add(PAUSE_DATE_MAX_MONTHS_RANGE, "M").toDate(),
    [initialDate]
  );

  return (
    <DatePickerModal
      open={isOpen}
      key={title ?? "pause-date-modal"}
      title={title ?? "Pause subscription"}
      bodyElement={
        bodyElement ?? (
          <Banner status="info">
            {bannerText ??
              "Please select a future date for subscription to auto-resume. The customer will be notified by email one week in advance of the unpause date."}
          </Banner>
        )
      }
      disabledDatesBefore={disabledDatesBefore}
      disabledDatesAfter={disabledDatesAfter}
      initialDate={initialUnpauseDate ? new Date(initialUnpauseDate) : moment(initialDate).add(1, "M").toDate()}
      onClose={closeModal}
      onConfirm={date => {
        onConfirm(date);
        closeModal();
      }}
    />
  );
}
