import { TypedTableColumnType } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";

import { CPSOrderHistoryTableColumnType } from "../../../AdminOrdersRoute/libs";
import {
  SubscriptionCancellationTableColumnKeyType,
  SubscriptionFailureTableColumnKeyType,
  SubscriptionTableColumnKeyType,
} from "../types";

export const subscriptionColumns: TypedTableColumnType<SubscriptionTableColumnKeyType> = {
  shopifyId: {
    name: "Subscription ID",
    paginatedValue: "shopifyId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  upcomingOrderDate: {
    name: "Upcoming order",
    paginatedValue: "upcomingOrderDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  emailOrName: {
    name: "Customer",
    paginatedValue: "emailOrName",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  email: {
    name: "Customer email",
    paginatedValue: "email",
    filtering: false,
    sorting: true,
    disabled: false,
  },
  estimatedTotal: {
    name: "Est. total",
    paginatedValue: "estimatedTotal",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  items: {
    name: "Items",
    paginatedValue: "items",
    filtering: true,
    sorting: false,
    disabled: false,
  },
  status: {
    name: "Status",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  createdDate: {
    name: "Created date",
    paginatedValue: "createdDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  sellingPlanId: {
    name: "Subscription program",
    paginatedValue: "sellingPlanId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};

export const subscriptionCancellationColumns: TypedTableColumnType<SubscriptionCancellationTableColumnKeyType> = {
  shopifyId: {
    name: "Subscription ID",
    paginatedValue: "shopifyId",
    filtering: true,
    sorting: true,
    disabled: false,
  },

  createdDate: {
    name: "Created date",
    paginatedValue: "createdDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  emailOrName: {
    name: "Customer",
    paginatedValue: "emailOrName",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  email: {
    name: "Customer email",
    paginatedValue: "email",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  estimatedTotal: {
    name: "Est. total",
    paginatedValue: "estimatedTotal",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  items: {
    name: "Items",
    paginatedValue: "items",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  cancellationReason: {
    name: "Cancellation reason",
    paginatedValue: "cancellationReason",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  status: {
    name: "Action taken",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};

export const subscriptionFailureColumns: TypedTableColumnType<SubscriptionFailureTableColumnKeyType> = {
  shopifyId: {
    name: "Subscription ID",
    paginatedValue: "shopifyId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  createdDate: {
    name: "Created date",
    paginatedValue: "createdDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  emailOrName: {
    name: "Customer",
    paginatedValue: "emailOrName",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  email: {
    name: "Customer email",
    paginatedValue: "email",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  estimatedTotal: {
    name: "Est. total",
    paginatedValue: "estimatedTotal",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  status: {
    name: "Action taken",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  retryCount: {
    name: "Retry count taken",
    paginatedValue: "retryCount",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  failureReason: {
    name: "Failure reason",
    paginatedValue: "failureReason",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  nextRetry: {
    name: "Next retry on",
    paginatedValue: "nextRetry",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  items: {
    name: "Items",
    paginatedValue: "items",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};

export const orderHistoryColumns: CPSOrderHistoryTableColumnType = {
  orderId: {
    name: "Order",
    paginatedValue: "orderId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  orderProcessedDate: {
    name: "Date",
    paginatedValue: "orderProcessedDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  status: {
    name: "Status",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  estimatedTotalNet: {
    name: "Total before tax",
    paginatedValue: "estimatedTotalNet",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  totalInCustomerCurrency: {
    name: "Cust. currency",
    paginatedValue: "totalInCustomerCurrency",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  items: {
    name: "Items",
    paginatedValue: "items",
    filtering: true,
    sorting: false,
    disabled: false,
  },
  smartrrOrderId: {
    name: "Smartrr Order ID",
    paginatedValue: "smartrrOrderId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};
